/*
 * Swiper
 *
 * Embeds a Swiper carousel component.
 * Use by wrappering SwiperSlide components in a Swiper component. The Swiper component exposes and handles the necessary settings required by Swiper.
 * 
 */

import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper as SwiperBase } from 'swiper/react';
import 'swiper/swiper-bundle.css';  // This can be optimize by only passing the relevant items from below and using minimized versions

//////////////////////////

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const useStyles = makeStyles({

  swiper: {
    // Styles for Swiper container
    height: '50vh',
    width: '100%',
    // Styles for Swiper elements
    '& .swiper-button-prev:after, & .swiper-button-next:after': {
      color: '#000',
    },
    '& .swiper-pagination-bullet-active': {
      background: '#ddd',
    },
    // Common styles for Swiper Slides
    // NOTE: Common styling of the SwiperSlide is implemented here rather than in a omponent that wraps the slide itself because the later breaks the swiper. The SwiperSlide seems to need to be implemented directly.
    "& .swiper-slide": {
      textAlign: 'center',
      borderRadius: '10px',
    },
  },

});

//////////////////////////

const propTypes = {
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  navigation: PropTypes.bool,
  pagination: PropTypes.object,
  scrollbar: PropTypes.object,
  children: PropTypes.any.isRequired,
  classNames: PropTypes.any,
  style: PropTypes.object,
};

const defaultProps = {
  spaceBetween: 5,
  slidesPerView: 1,
  navigation: true,
  pagination: { clickable: true },
  scrollbar: { draggable: true },
};

/**
  * Create a Swiper carousel like component that should be populated with SwiperSlide components as children.
  * @param {*} props 
  */

export const Swiper = props => {

  const classes = useStyles({});
  const {
    children,
    className,
    ...rest
  } = props;

  //////
  
  return (

    <SwiperBase
      className = {clsx(classes.swiper, className)}
      {...rest}
      >
      {children}
    </SwiperBase>

  );

};

Swiper.propTypes = propTypes;
Swiper.defaultProps = defaultProps;
export default Swiper;
export { SwiperSlide } from 'swiper/react'; // SwiperSlide is exported without change so it can be imported from the same file


