import React from 'react';
import Typography from '@material-ui/core/Typography';
import PageLoadBars from './PageLoadBars';

//////////////////////////

const FeatureDemos = props => {

  return ( <>

    <Typography variant="h2">
      Project Template Features
    </Typography>

    <PageLoadBars/>

  </> );
};

export default FeatureDemos;
