import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import Button from '@material-ui/core/Button';
import NProgress from 'gatsby-plugin-nprogress';

//////////////////////////

// const useStyles = makeStyles( theme => ({
// }));

//////////////////////////

const PageLoadBars = props => {

  // const classes = useStyles(props);

  //////

  return ( <>


    <Typography variant="h3">
      Page load bar
    </Typography>

    <Typography variant="body1">
      nProgress is an included Gatsby plugin that automatically shows a load bar if an internal page (accessed through a Link component from another internal page) takes longer than 1 second to load.
    </Typography>
    <Typography variant="body1">
      Colors and other config options can be set in gatsby-config.js or dynamically per page.
      It can also be dynamically controlled - as per the below buttons.
    </Typography>
    <ul>
      <li>
        <Typography variant="body1">
          <Link to="https://developer.aliyun.com/mirror/npm/package/nprogress">Documentation</Link>
        </Typography>
      </li>
    </ul>



    <div>

      <Button
        variant = "contained"
        size = "small"
        onClick={NProgress.start}
      >
        Start incrementing
      </Button>

      <Button
        variant = "contained"
        size = "small"
        onClick={() => NProgress.set(0.5)}
      >
        Set to 50%
      </Button>

      <Button
        variant = "contained"
        size = "small"
        onClick={NProgress.inc}
      >
        Increment slightly
      </Button>

      <Button
        variant = "contained"
        size = "small"
        onClick={() => NProgress.done()}
      >
        Set to complete
      </Button>

    </div>


  </> );
};

export default PageLoadBars;
