import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import useSharedImageData from '/src/images/shared-queries';
import Img from 'gatsby-image';
import Button from '@material-ui/core/Button';
import ModalVideoLauncher from '/src/components/ModalVideoLauncher';
import { Swiper, SwiperSlide } from '/src/components/Swiper';

//////////////////////////

const useStyles = makeStyles( theme => ({

  slide1: {
    background: theme.palette.info.main,
  },
  slide2: {
    background: theme.palette.success.main
  },
  slide3: {
    background: theme.palette.warning.main
  },
  slide4: {
    background: theme.palette.error.main
  },

  limitedWidthButton: {
    width: '13ch',
  }

}));

//////////////////////////

const useImageData = () => {

  // NOTE: Image paths are always relative to the images folder.
  const imageData = useStaticQuery(graphql`
      query {
        example: file(relativePath: {eq: "pages/component-demos/ElementDemos/example_disruption-process.png"}) {

          sameFileDemo: childImageSharp {
            fixed600: fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }

        }
      }
    `);

    return imageData;
}

//////////////////////////

const ElementDemos = props => {

  const theme = useTheme();
  const classes = useStyles(props);
  const sharedImageData = useSharedImageData();
  const imageData = useImageData();

  //////    

  return ( <div>
    
    <Typography variant="h2">
      Video
    </Typography>

    <ModalVideoLauncher
      channel = "youtube"
      videoId = "zid9AQUHH-E"
      >
      <Button
        type = "button"
        variant = "contained"
        color = "primary"
        size = "small"
        className = {classes.limitedWidthButton}
        >
        Open the Youtube Video
      </Button>
    </ModalVideoLauncher>

    <ModalVideoLauncher
      channel = "vimeo"
      videoId = "485714761"
      >
      <Typography variant="body1">
        This is the 1st of two lines of text.<br/>
        This is the second. Click these to launch a vimeo video.
      </Typography>
    </ModalVideoLauncher>


    <Typography variant="h2">
      Swipable Carousel
    </Typography>
    <ul>
      <li>
        <Typography variant="body1">
          <Link to="https://swiperjs.com/react/#usage">Swiper usage</Link>
        </Typography>
      </li>
    </ul>
    
    <Swiper>

      <SwiperSlide className={clsx(classes.slide1)}>
        <Typography variant="h4">
          Slide 1
        </Typography>
        <Typography variant="body1">
          Info color
        </Typography>
      </SwiperSlide>

      <SwiperSlide className={clsx(classes.slide2)}>
        <Typography variant="h4">
          Slide 2
        </Typography>
        <Typography variant="body1">
          Success color
        </Typography>
      </SwiperSlide>

      <SwiperSlide className={clsx(classes.slide3)}>
        <Typography variant="h4">
          Slide 3
        </Typography>
        <Typography variant="body1">
          Warning color
        </Typography>
      </SwiperSlide>

      <SwiperSlide className={clsx(classes.slide4)}>
        <Typography variant="h4">
          Slide 4
        </Typography>
        <Typography variant="body1">
          Error color
        </Typography>
      </SwiperSlide>

    </Swiper>


    <Typography variant="h2">
      Images
    </Typography>
    <Typography variant="body1">
      Images are referred to in code though an imported data object. This object must contain all the graphQL queries necessary to preprocess your images.
      Read more about gatsby-image here:
    </Typography>
    <ol>
      <Typography variant="body1">
        <li><Link to="https://using-gatsby-image.gatsbyjs.org/">Demonstration</Link></li>
        <li><Link to="https://www.gatsbyjs.com/plugins/gatsby-image/">Documentation</Link></li>
        <li><Link to="https://dev.to/alexabruck/understanding-gatsby-image-part-1-graphql-generated-files-markup-2ehd">Article</Link></li>
      </Typography>
    </ol>
        
    <Typography variant="body1">
      Fixed width images have their size specified in the GraphQL:
    </Typography>
    <div>
      <Img fixed={sharedImageData.example.sizeDemo.fixed400} />
    </div>
    <div>
      <Img fixed={sharedImageData.example.sizeDemo.fixed600} />
    </div>

    <Typography variant="body1">
      Fluid width images will be built into multiple different sized images (usually 3) and selected on first load:
    </Typography>
    <div>
      <Img fluid={sharedImageData.example.sizeDemo.fluid1200} />
    </div>

    <Typography variant="body1">
      Background images can be faked by positioning the image absolutely.<br/>
      This is worth the trouble as most will need to be fluid. It allows gatsby-image to continue managing image selection based on viewport size (or image size(?) - check this).
    </Typography>
    <div
      style = {{
        position: "relative",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 0,  // creating z-index group for children
      }}
      >
      <Img
        fluid = {sharedImageData.example.sizeDemo.fluid1200}
        style = {{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />
      <Typography
        variant = "body1"
        style = {{
          color: "white",
          textAlign: "center"
        }}
        >
        Background Image
      </Typography>
    </div>

    <Typography variant="body1">
      While the above image tags use a shared graphQL definition (placed in the images folder for easy communal use), the graphQL for the below image is defined in this component itself.
    </Typography>
    <div>
      <Img fixed={imageData.example.sameFileDemo.fixed600} />
    </div>

    <Typography variant="h3">
      Gatsby image process refinement
    </Typography>
    <Typography variant="body1">
      Gatsby image components require very verbose GraphQL code which is evaluated during the build process. This build process means that the graphQL queries can't use variables and thus can't be encapsulated into a separate component for simplification.
    </Typography>
    <Typography variant="body1">
      Research shows that there are developments to Gatsby in the roadmap that will allow simplification of this.
    </Typography>
    <ol>
      <Typography variant="body1">
        <li><Link to="https://gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/">New Gatsby Image plugin (beta)</Link></li>
        <li><Link to="https://github.com/gatsbyjs/gatsby/discussions/27950">Gatsby Image plugin repo</Link></li>
        <li><Link to="https://www.npmjs.com/package/gatsby-plugin-image">Gatsby Image plugin npm rep</Link></li>
        <li><Link to="https://twitter.com/i/status/1212386859579428864">Querying 2.0 twitter discussion</Link></li>
        <li><Link to="https://gist.github.com/sidharthachatterjee/e0c961fd92ce287dc020939037b915ce">Querying 2.0 readme</Link></li>
        <li><Link to="https://twitter.com/daledesilva/status/1354589785231384584">Personal twitter question</Link></li>
        <li><Link to="https://github.com/gatsbyjs/gatsby/discussions/10482#issuecomment-476606211">Gatsby issue discussion</Link></li>
      </Typography>
    </ol>


  </div> );
};

export default ElementDemos;






