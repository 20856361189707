import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import { Button } from '@material-ui/core';

//////////////////////////

const useStyles = makeStyles( theme => ({

  // TODO: Using 'importants' here to overide theme defined values appears to be the only realistic solution that will work for all kinds of components
  // Ref: https://material-ui.com/customization/components/
  highlightedLink: {
    color: theme.palette.primary.contrastText + '!important', // !important is used to override properties defined in theme as they have too high specificity
    backgroundColor: theme.palette.primary.main,
    padding: '0.3em 0.5em',
    borderRadius: '12px 12px 12px 2px',
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.tertiary.contrastText + '!important',
      backgroundColor: theme.palette.tertiary.main + '!important',
    },
    '&:hover, &:active': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText + '!important',
    }
  },

}));

//////////////////////////

const LinkingDemos = (props) => {

  const classes = useStyles(props);

  //////

  return ( <>

    <div>
    
      <Typography variant="h2">
        Buttons &#38; Links
      </Typography>

      <div>

        <Typography variant="h3">
          Buttons
        </Typography>

        <Typography variant="body1">
          Small contained button
        </Typography>
        <Button variant="contained" color="primary" size="small">
          Primary color
        </Button>
        <Button variant="contained" color="secondary" size="small">
          Secondary color
        </Button>

        <Typography variant="body1">
          Large contained button
        </Typography>
        <Button variant="contained" color="primary" size="large">
          Primary color
        </Button>
        <Button variant="contained" color="secondary" size="large">
          Secondary color
        </Button>
        
        <Typography variant="body1">
          Small outlined button
        </Typography>
        <Button variant="outlined" color="primary" size="small">
          Primary color
        </Button>
        <Button variant="outlined" color="secondary" size="small">
          Secondary color
        </Button>
        
        <Typography variant="body1">
          Large outlined button
        </Typography>
        <Button variant="outlined" color="primary" size="large">
          Primary color
        </Button>
        <Button variant="outlined" color="secondary" size="large">
          Secondary color
        </Button>


        <Typography variant="h3">
          Links
        </Typography>
        <Typography variant="body1">
          In place of all anchor tags, an internal Link component should be used. This component automatically applies the Anchor tag or Gatsby Link componnent depending on whether the link is external or internal. It also addes a default target value as well as adds in rel="noreferrer and noopener" if necessary.
        </Typography>
        <Typography variant="body1">
          Links should always be used inside a Typography component so that they adopt the correct styling. They will then overide the Typography components colours with specific link colours defined in the Theme's palette. For custom styling changes, either apply a class to the link or edit the Link component itself for a global change.
        </Typography>
        <Typography variant="h4">
          An example of <Link to="http://www.tbwa.com.au">a link</Link> in a heading
        </Typography>
        <Typography variant="body1">
          An example of <Link to="http://www.tbwa.com.au">a link</Link> inside some body text. A <Link to="http://www.tbwa.com.au" className={classes.highlightedLink}>link's styling</Link> can also be overridden by a custom class.
        </Typography>
        
      </div>
    
    </div>

  </> )

}

export default LinkingDemos;
