/*
 * ModalVideoLauncher
 *
 * A wrapper that can be used around any element that should launch a video in a modal when clicked.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ReactModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

//////////////////////////

const useStyles = makeStyles({

  clickableGroup: {
    cursor: "pointer",
  },

});

//////////////////////////

const propTypes = {
  props: PropTypes.shape({
    channel: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    startOpen: PropTypes.bool,
    children: PropTypes.any.isRequired,
  }),
}

/**
 * Encapsulate video modal and related states for easier use.
 * Channel can be 'youtube' or 'vimeo'.
 */

export const ModalVideoLauncher = props => {

  const {
    channel,
    videoId,
    startOpen = false,
    children,
  } = props;
  const [isOpen, setIsOpen] = useState(startOpen);
  const classes = useStyles({});

  // Handle either children as a single button or multiple elements
  let preparedChildren;
  if( React.Children.count(children) === 1 ){
    // There's only one child, so give it the click action directly
    // TODO: May need to check if it's an interactive element (button, a, etc). If not, might need to be wrapped in one
    preparedChildren = React.cloneElement(props.children, { onClick: () => setIsOpen(true) })

  } else {
    // There's multiple children, so wrap it in a button
    // NOTE: The span might create an Aria based lint error here, but abiding by it creates more errors.
    // Potential solution is to remove that particular linting rule because it contradicts itself - or rethink this component.
    preparedChildren = (
      <span
        onClick = {()=>setIsOpen(true)}
        onKeyDown = {()=>setIsOpen(true)}
        role = 'dialog'
        className = {clsx(classes.clickableGroup)}
      >
        {children}
      </span>
    );

  }

  //////

  return ( <>
    <ReactModalVideo
      channel = {channel}
      isOpen = {isOpen}
      videoId = {videoId}
      onClose = {() => setIsOpen(false)}
    />
    {preparedChildren}
  </> );

};

ModalVideoLauncher.propTypes = propTypes;
export default ModalVideoLauncher;
