import React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Grid,
  FormLabel,
  FormControl,
  CircularProgress,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Select,
  FormControlLabel,
} from '@material-ui/core';

//////////////////////////

const useStyles = makeStyles({
  form: {
    width: '300px',
  },
  fieldWrapper: {
    marginBottom: '2rem',
    paddingRight: '2.3em',
  },
});

//////////////////////////

const FormDemos = (props) => {

  const classes = useStyles(props);
  
  return ( <>

    <div>
    
      <Typography
        variant="h2"
        color="primary"
        align="center"
        className={clsx(classes.sectionHeading)}
        >
        Forms
      </Typography>


      <Grid container>


        <Grid item xs={12} md={12}>
          <Typography variant="h4" color="secondary">
            Text fields with validation style
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Field 1"
            required
            variant="outlined"
            defaultValue="Valid filed"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Field 2"
            required
            variant="outlined"
            fullWidth
            error
            helperText="Error."
            defaultValue="Error value"
          />
        </Grid>
        <Grid item xs={12} md={3} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Field 3"
            fullWidth
            // autoFocus
            variant="outlined"
            helperText="This field is optional. Click to see the focus style."
            color="secondary"
          />
        </Grid>
        <Grid item xs={12} md={3} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Field 4"
            variant="outlined"
            fullWidth
            disabled
            helperText="This field is disabled."
          />
        </Grid>


        <Grid item xs={12} md={12}>
          <Typography variant="h4" color="secondary">
            Fields with different type
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Number Field"
            variant="outlined"
            defaultValue={1}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={4} className={clsx(classes.fieldWrapper)}>
          <TextField
            label="Email Field"
            variant="outlined"
            defaultValue="email@exapmle.com"
            fullWidth
            type="email"
          />
        </Grid>
        <Grid item xs={12} md={4} className={clsx(classes.fieldWrapper)}>
          <NativeSelectField />
        </Grid>


        <Grid item xs={12} md={12}>
          <Typography variant="h4" color="secondary">
            Radio field
          </Typography>
        </Grid>
        <RadioField />


        <Grid item xs={12} md={12}>
          <Typography variant="h4" color="secondary">
            Checkbox field
          </Typography>
        </Grid>
        <CheckboxField />


        <Grid item xs={12} md={12}>
          <Typography variant="h4" color="secondary">
            Submit buttons
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className={clsx(classes.fieldWrapper)}>
          <Typography variant="body1" color="secondary">
            Default
          </Typography>
          <Button
            variant="contained"
            className={clsx(classes.demoButton)}
            color="primary"
            size="small"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={12} className={clsx(classes.fieldWrapper)}>
          <Typography variant="body1" color="secondary">
            Disabled
          </Typography>
          <Button
            variant="contained"
            className={clsx(classes.demoButton)}
            color="primary"
            size="small"
            disabled
            >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={12} className={clsx(classes.fieldWrapper)}>
          <Typography variant="body1" color="secondary">
            Processing
          </Typography>
          <Button
            variant="contained"
            className={clsx(classes.demoButton)}
            color="primary"
            size="small"
            disabled
            >
            <CircularProgress size="20px" thickness={5} />
          </Button>
        </Grid>
        

      </Grid>
    
    </div>

  </> )

}

export default FormDemos;


//////////////////////////


/**
 * Create dropdown example
 * @param {*} props 
 */

const NativeSelectField = (props) => {

  const classes = useStyles(props);
  const [state, setState] = React.useState({
    select: '',
  });

  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  //////

  return (
    
    <FormControl variant="outlined" fullWidth className={clsx(classes.form)}>
      <InputLabel htmlFor="outlined-native-select">Select</InputLabel>
      <Select
        native
        value={state.option1}
        onChange={handleChange}
        label="Select"
        inputProps={{
          name: 'select',
          id: 'outlined-native-select',
        }}
        >
        <option value={1}>Value 1</option>
        <option value={2}>Value 2</option>
        <option value={3}>Value 3</option>
      </Select>
    </FormControl>

  );

};


/**
 * Create a example set of Radio Select options
 */

const RadioField = () => {

  const [value, setValue] = useState('value1');
  const handleChange = event => {
    setValue(event.target.value);
  };

  /////

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Select a value:</FormLabel>
      <RadioGroup
        aria-label="radio"
        name="radio"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="value1" control={<Radio />} label="Value 1" />
        <FormControlLabel value="value2" control={<Radio />} label="Value 2" />
        <FormControlLabel value="value3" control={<Radio />} label="Value 3" />
        <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="Disabled option"
        />
      </RadioGroup>
    </FormControl>
  );
};


/**
 * Create a checkbox item example.
 */

const CheckboxField = () => {
  
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkbox1: true,
  });
  const { checkbox1 } = state;
  const error = checkbox1 !== true;

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //////

  return (
    <>
      <FormControl
        required
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">Check the box:</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox1}
              onChange={handleChange}
              name="checkbox1"
            />
          }
          label="Check me"
        />
      </FormControl>
    </>
  );

};


