import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

//////////////////////////

const useStyles = makeStyles( theme => ({
  colorsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  colorPaper: {
    height: '100px',
    width: '100px',
    textAlign: 'center',
    margin: '20px',
    position: 'relative',
    '& *': {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      transform: 'translateY(-50%)',
      margin: 0,
      padding: 0,
      color: 'inherit',
    }
  },
  primaryPaper: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  secondaryPaper: {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
  },
  tertiaryPaper: {
    color: theme.palette.tertiary.contrastText,
    background: theme.palette.tertiary.main,
  },
  infoPaper: {
    color: theme.palette.info.contrastText,
    background: theme.palette.info.main,
  },
  successPaper: {
    color: theme.palette.success.contrastText,
    background: theme.palette.success.main,
  },
  warningPaper: {
    color: theme.palette.warning.contrastText,
    background: theme.palette.warning.main,
  },
  errorPaper: {
    color: theme.palette.error.contrastText,
    background: theme.palette.error.main,
  },
}));

//////////////////////////

const ColorDemos = (props) => {

  const classes = useStyles(props);

  //////
  
  return ( <>

    <div>
    
      <Typography variant="h2">
        Colours
      </Typography>

      <div className={clsx(classes.colorsWrapper)}>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.primaryPaper)}
          >
          <Typography>
            Primary
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.secondaryPaper)}
          >
          <Typography>
            Secondary
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.tertiaryPaper)}
          >
          <Typography>
            Tertiary<br/>
            (non MUI standard)
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.infoPaper)}
          >
          <Typography>
            Info
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.successPaper)}
          >
          <Typography>
            Success
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.warningPaper)}
          >
          <Typography>
            Warning
          </Typography>
        </Paper>

        <Paper
          elevation = {3}
          className={clsx(classes.colorPaper, classes.errorPaper)}
          >
          <Typography>
            Error
          </Typography>
        </Paper>

      </div>
    
    </div>

  </> )

}

export default ColorDemos;
