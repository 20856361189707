import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//////////////////////////

const useStyles = makeStyles( theme => ({

  styleExample: {
    textTransform: "uppercase",
  },

  styleWithPropsExample: {
    backgroundColor: theme.palette.primary.main,
    padding: "2em",
  },

}));

//////////////////////////

const TypographyDemos = (props) => {

  const classes = useStyles(props);

  //////

  return ( <>

    <div>
    
      <Typography variant="h2">
        Typography
      </Typography>


      <div>

      <Typography variant="h1">
          Heading - H1
        </Typography>

        <Typography variant="body1">
          Body1 text with primary color: <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec diam
          porttitor felis aliquam interdum eget a massa.
        </Typography>

        <Typography variant="h2">
          Heading - H2
        </Typography>

        <Typography variant="body1">
          Body1 text with secondary color: <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec diam
          porttitor felis aliquam interdum eget a massa.
        </Typography>

        <Typography variant="h3">
          Heading - H3
        </Typography>

        <Typography variant="body2">
          Body2 text with primary color: <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec diam
          porttitor felis aliquam interdum eget a massa.
        </Typography>

        <Typography variant="h4">
          Heading - H4
        </Typography>

        <Typography variant="body2">
          Body2 text with secondary color: <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec diam
          porttitor felis aliquam interdum eget a massa.
        </Typography>

        <Typography variant="h5">
          Heading - H5
        </Typography>

        <Typography variant="subtitle1">
          Subtitle1 text with primary color
        </Typography>

        <Typography variant="h6">
          Heading - H6
        </Typography>
        
        <Typography variant="subtitle2">
          Subtitle2 text with primary color
        </Typography>

        <Typography variant="h3">
          Overriding
        </Typography>

        <Typography variant="body1" className={clsx(classes.styleExample)}>
          Any typography element can be overriden by including a style object or a classname like this text is.
        </Typography>

        <Typography variant="body1" className={clsx(classes.styleWithPropsExample)}>
          And can even pass props when defining the style like this.
        </Typography>

      </div>
      
    
    </div>

  </> )

}

export default TypographyDemos;
