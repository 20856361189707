
///////////////////////////////////
// Common imports are left here and commented out for reference when creating new pages
///////////////////////////////////

// Primary Imports
//////////////////
import PropTypes from 'prop-types';
import React from 'react';
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { useContext } from 'react';

// Settings
///////////
// import { PATHS } from '/src/globals';

// Common Styling
/////////////////
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
// import { Link } from '/src/components/Link';
// import useImageData from '/src/images/queries';
// import Img from 'gatsby-image';

// Page specific imports
////////////////////////
import {
  TransitionFade,
} from '/src/components/animators';

import { METADATA } from '/src/globals';
import ColorDemos from '/src/page-sections/demo/component-demos/ColorDemos';
import TypographyDemos from '/src/page-sections/demo/component-demos/TypographyDemos';
import LinkingDemos from '/src/page-sections/demo/component-demos/LinkingDemos';
import FormDemos from '/src/page-sections/demo/component-demos/FormDemos';
import ElementDemos from '/src/page-sections/demo/component-demos/ElementDemos';
import FeatureDemos from '/src/page-sections/demo/component-demos/FeatureDemos';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const ComponentDemos = (props) => {

  
  
  return ( <>

    <Helmet>
      <title>Component Demos{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>

    <PageWrapper>
  
      <TransitionFade duration={1000}>
        <Typography variant="h1">
          Components
        </Typography>
      </TransitionFade>
        
      <TypographyDemos/>
      <ColorDemos/>
      <LinkingDemos/>
      <FormDemos/>
      <FeatureDemos/>
      <ElementDemos/>
        

      <DemoNavigation/>

    </PageWrapper>

  </> )

}

ComponentDemos.propTypes = propTypes;
export default ComponentDemos;
