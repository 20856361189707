import { useStaticQuery, graphql } from 'gatsby';

//////////////////////////
// These queries are processed at build time.
// They define how images should be prepared and the data structure through which they are accessed
//////////////////////////
// NOTE: Image paths are always relative to the images folder. 

const useImageData = () => {

  const imageData = useStaticQuery(graphql`
      query {
        example: file(relativePath: {eq: "shared/example.jpg"}) {
  
          sizeDemo: childImageSharp {
  
            fixed400: fixed(width: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
  
            fixed600: fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp
            }
  
            fluid1200: fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
            
          }
  
          preloadDemo: childImageSharp {
  
            fixedWithBlur: fixed(width: 1000) {
              ...GatsbyImageSharpFixed_withWebp
            }
  
            fixedWithTrace: fixed(width: 1000) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
            
          }
        }
      }
    `);

    return imageData;
}

export default useImageData;
